import { createContext, useState } from "react";

const ThemeChanging = createContext();

function ThemeContext({ children }) {

    const [theme, setTheme] = useState("light");
    const [themeColor, setThemeColor] = useState("blue");

    return (
        <ThemeChanging.Provider value={{theme, setTheme, themeColor, setThemeColor}}>
            {children}
        </ThemeChanging.Provider>
    );

}

export { ThemeContext, ThemeChanging }