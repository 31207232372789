import "./Bullet.css"

function Bullet({ icon, text }) {
    const icoClasses = "ico " +  icon;
    return (
        <div className="icon bullet">
            <table>
                <tbody>
                    <tr className="row">
                        <td><span className={icoClasses}></span></td>
                        <td className="text">{text}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export { Bullet }