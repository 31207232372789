import './PopCard.css';
import { useContext } from 'react';
import { CVData } from '../../contexts/CVDataContext';

function PopCard({closePop}) {
    
    const data = useContext(CVData)
    const personDetails = data.personalDetails
    
    function clickClose() {
        closePop(false);
    }
    
    return (
        <div className='popcontact'>
            <div className='screenl'><span>|&nbsp;</span></div>
            <div className='contactdetails'>
                <h3>Contact Me</h3>
                <div>
                    <span className='ico iemails'></span>
                    <span>{personDetails.email}</span>
                </div>
                <div>
                    <span className='ico iphone'></span>
                    <span className='ico iwhatsapp'></span>
                    <span>{personDetails.mobile}</span>
                </div>
            </div>
            <div className='closebtn' onClick={clickClose}>
                <span className='ico ixs'></span>
            </div>
            <div className='screenr'><span>&nbsp;|</span></div>
        </div>
    );
}

export { PopCard }