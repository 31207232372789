import './ButtonArrow.css';

function ButtonArrow({func, href, text}) {

    function buttonClicked(event) {
        event.preventDefault()
        if (href==null || href==undefined || href=="") func(true);
    }

    return (
        <div className='btn'>
            <a href={href} onClick={buttonClicked}>
                {text}
                <span className='arrowAnim'>
                    <span className='ico iarrr'></span>
                    <span className='ico iarrr'></span>
                    <span className='ico iarrr'></span>
                </span>
            </a>
        </div>
    );

}

export { ButtonArrow }