import { createContext, useState, useEffect } from "react";

const ContentRouting = createContext();

function ContentRoutingContext({ children }) {

    const [contentPath, setContentPath] = useState(window.location.pathname);
    const [profileName, setProfileName] = useState("pravin");

    useEffect(
        () => {
            document.addEventListener("popstate", handlePopstate);

            function handlePopstate() {
                setContentPath(window.location.pathname);
            }

            return () => {
                document.removeEventListener("popstate", handlePopstate);
            }
        },
        []
    );

    function setNavPath(event, path) {
        if (!event.ctrlKey) event.preventDefault();
        const npath = "/"+profileName+"/"+path.replaceAll(" ", "");
        window.history.pushState({}, '', npath);
        setContentPath(npath);
    }

    function setWindowPath(path) {
        const npath = "/"+profileName+"/"+path.replaceAll(" ", "");
        window.history.pushState({}, '', npath);
        setContentPath(npath);
    }

    return (
        <ContentRouting.Provider value={{contentPath, setNavPath, setWindowPath, profileName}}>
            {children}
        </ContentRouting.Provider>
    );

}

export { ContentRoutingContext, ContentRouting }