import './Career.css';
import { useContext, useReducer } from 'react';
import { CVData } from '../../../contexts/CVDataContext';
import { Timeline } from './timeline/Timeline';
import { CareerDetails } from './careerdetails/CareerDetails';

const SELECT_CAREER = "Select Career";

function reducer(state, action) {
    switch (action.type) {
        case SELECT_CAREER:
            return (
                { ...state, careerSelected: action.payload }
            );
        default:
            return state;
    }
}

function Career() {

    const career = useContext(CVData);
    const careerData = career.careerDetails;
    const [state, dispatch] = useReducer(reducer, {
        careerSelected : careerData.length - 1 
    });
    
    
    function careerClick(cardIndex) {
        dispatch({
            type: SELECT_CAREER,
            payload: cardIndex
        })
    }

    return (
        <section className='career section'>
            <div className='container'>
                <div className='crrow'>
                    <h3>Career</h3>
                </div>
                <div className='crrow'>
                    <Timeline cards={careerData} selectedCard={state.careerSelected} action={careerClick} />
                </div>
                <div className='crrow'>
                    <div className='focused'>
                        <CareerDetails card={careerData[state.careerSelected]} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export { Career }