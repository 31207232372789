import { Bullet } from "../../../bullet/Bullet"

function ExperienceHighlights({bullets}) {

    const allBullets = bullets.map((bullet, i)=>{
        return <Bullet icon="ishare" text={bullet} key={i} />
    });

    return (
        <div className='bullets'>
            {allBullets}
        </div>
        
    );

}

export { ExperienceHighlights }