import { Bullet } from "../../../bullet/Bullet";

function CareerDetails({ card }) {

    const allDescription = card.description.map((desc, i) => {
        return <p key={i}>{desc}</p >
    })

    const allAchievements = card.achievements.map((ach, i) => {
        return <Bullet icon="iarrowr" text={ach} key={i} />
    })

    return (
        <div className='careerdetails'>
            <div className='comprofile'>
                <span className='company'>
                    <span className='name'>{card.organizationName}</span>
                </span>
                <span className='period'>
                    <span className='from'>{card.from.month}'{card.from.year}</span>
                    <span>-</span>
                    <span className='to'>{card.to.month}'{card.to.year}</span>
                </span>
            </div>
            <div className='caption'>{card.designation}</div>
            <div className='description'>
                {allDescription}
            </div>
            <div className='highlights'>
                <div className='points'>
                    <div className='point'>
                           {allAchievements}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { CareerDetails }