import './Who.css';
import { useContext } from 'react';
import { ContentRouting } from '../../../contexts/ContentRoutingContext';
import { ButtonArrow } from '../../button/ButtonArrow';
import { CVData } from '../../../contexts/CVDataContext';
import { ExperienceHighlights } from './experiencehighlights/ExperienceHighlights';

function Who() {
    
    const cr = useContext(ContentRouting);
    const data = useContext(CVData)
    const profileSummary = data.experienceSummary;
    const personalDetails = data.personalDetails;
    const profImage = require('../../../images/profile/' + cr.profileName + '.png');
    
    return (
        <section className='home section'>
            <div className='container'>
                <div className='wrow'>
                    <div className='home-info'>
                        <div className='text'>
                            <div className='name'>
                                {profileSummary.greetingsText}, I am
                                <span>&nbsp;</span>
                                <span className='name highlight'>
                                    {personalDetails.firstName} {personalDetails.middleName} {personalDetails.lastName}
                                </span>
                            </div>
                            <h3 className='caption'>
                                {profileSummary.profileHeading.text}
                                <span>&nbsp;</span>
                                <span className='caption highlight'>
                                    {profileSummary.profileHeading.highlighted}
                                </span>
                            </h3>
                            <p className='summary'>
                                {profileSummary.profileSummary}
                            </p>
                            <ExperienceHighlights bullets={profileSummary.profileHighlights} />
                        </div>
                    </div>
                    <div className='profile-image'>
                        <div className='img-container'>
                            <img src={profImage} alt='Profile Image' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export { Who }