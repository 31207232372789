import { Bullet } from "../../../bullet/Bullet";

function CompanyWins({ win }) {

    const companyWins = win.companyWins.map((cwin, index) => {
        
        const winContents = cwin.winDescription.map((content, i)=>{
            return <Bullet icon="iarrowr" text={content} key={i} />;
        });
        
        return (
            <div className='crow' key={index}>
                <div className='heading'>{cwin.winTitle}</div>
                <div className='content'>{winContents}</div>
            </div>
        );
    });

    return (
        <div className='contentwrapper'>
            {companyWins}
        </div>
    );

}

export { CompanyWins }