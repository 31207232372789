import './Wins.css';
import { useContext, useReducer } from 'react';
import { CVData } from '../../../contexts/CVDataContext';
import { Companies } from './companies/Companies';
import { CompanyWins } from './companywins/CompanyWins';

const CHANGE_COMPANY = "Change Company"

const reducer = (state, action) => {
    switch (action.type) {
        case CHANGE_COMPANY:
            return { ...state, companyIndex: action.payload };
        default:
            return state;
    }
}

function Wins() {

    const data = useContext(CVData);
    const winsData = data.wins;
    const [state, dispatch] = useReducer(reducer, {
        companyIndex: 0
    });

    function changeCompanyIndex(compIndex) {
        dispatch({
            type: CHANGE_COMPANY,
            payload: compIndex
        });
    }

    return (
        <section className='wins section'>
            <div className='container'>
                <div className='wirow'>
                    <div className='heading'>
                        <h3>
                            Wins
                        </h3>
                    </div>
                    <div className='winscontent'>
                        <Companies wins={winsData} selected={state.companyIndex} companyChange={changeCompanyIndex} />
                        <div className='wincontent'>
                            <CompanyWins win={winsData[state.companyIndex]} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export { Wins }