import { createContext } from "react";

const CVData = createContext();

function CVDataContext({ children }) {

    const cvObject = {
        "profileId": {
            "id": "1",
            "profileName": "pravinrajpaul"
        },
        "personalDetails": {
            "firstName": "Pravin Raj Paul",
            "middleName": "",
            "lastName": "Raveendran",
            "preferredName": "Pravin",
            "dob": 337199400,
            "gender": "Male",
            "nationality": "Indian",
            "email": "pravinrajpaul@gmail.com",
            "mobile": "(+91) 8754450054",
            "website": "http://www.geevin.in/pravin"
        },

        "experienceSummary": {
            "greetingsText": "Hello",
            "profileHeading": {
                "text": "A passionate",
                "highlighted": "IT Quality Architect and Innovation Leader"
            },
            "profileSummary": "I am a Versatile, Technophile and Diligent IT professional with around 2 decades of diverse IT experience including Quality Engineering, Delivery Management and Full Stack Development. Proven track record of successfully driving Transformation Programs for Quality Engineering, Setting up Capabilities, Innovation initiatives and Delivery Management. Below are some of my career specializations:",
            "profileHighlights": [
                "Advisement for Quality Engineering on technology modernization programs for large financial institutions, Telco and Healthcare by assessing current state, engineer solutions and plan & implement, allowing organizations to scale up",
                "Formulated and Implemented niche Testing Center Of Excellence models for various organizations",
                "Ideated, developed and implemented innovative Quality Engineering and Engineering solutions",
                "Spearheaded numerous evaluation for various Quality tools and processes",
                "Orchestrated product and solution demonstration, coupled with strategic mindset and customer success"
            ]
        },

        "careerDetails": [
            {
                "organizationName": "Electronet Equipments Private Ltd",
                "from": {
                    "month": "Sep",
                    "year": "2003"
                },
                "to": {
                    "month": "Oct",
                    "year": "2005"
                },
                "designation": "R & D Engineer",
                "description": [
                    "Proficient engineering player showcased engineering build capabilities with high quality and ontime deliveries",
                    "Was quick to plan and adapt to product changes",
                    "Was at the peak of showcasing tenacity and hardwork attitude"
                ],
                "achievements": [
                    "Development, test and delivery of an industrial engineering portal using Java, JSP, JDBC, Spring, Tomcat server, Oracle DB",
                    "Conduct code and product quality checks, version control, document, deploy and initiate end user communication"
                ]
            },
            {
                "organizationName": "Tech Mahindra Ltd",
                "from": {
                    "month": "Oct",
                    "year": "2005"
                },
                "to": {
                    "month": "Jan",
                    "year": "2012"
                },
                "designation": "Project Manager",
                "description": [
                    "This was my stepping stone in to dedicated software quality group where I grew from Software Engineer to Project Manager during 7 long years",
                    "I was also on delivery management role for an year towards later part of my journey with the organization",
                    "Here I started my test automation journey with QTP and Test Partner",
                    "Contemporarily was a domain expert on Telecom BSS, specially on mobility",
                ],
                "achievements": [
                    "Lead various quality deliveries for British Telecom with quality bestpractices",
                    "QA strategizing for Functional/ETL and Automation testing, Planning, Team Management, Defect Triage",
                    "Was leading a team of 5 to 10 members for Business Mobile quality group",
                    "Delivered projects with onshore offshore model engaging effectively with both parties"
                ]
            },
            {
                "organizationName": "Infosys Ltd",
                "from": {
                    "month": "Jan",
                    "year": "2012"
                },
                "to": {
                    "month": "Mar",
                    "year": "2020"
                },
                "designation": "Senior Project Manager",
                "description": [
                    "It was a 7 years long ride with Infosys",
                    "Where I got exposed, adopted and had driven shift of commercialized tools to opensource cost saving trend, continuous testing, agile delivery model, financial domain and ITIL framework",
                    "On the other hand I was managing multiple projects for client in a service consulting model, produced revenue and effort forecasts, skill gap analysis, project maturity analysis, risk management, monitoring KPIs which lead to multiple stakeholder management in diverse combination of client engagements and environments",
                ],
                "achievements": [
                    "Lead various transitioning engagement from incumbent to vendor and vendor to vendor models for RBS, HSBC and many",
                    "Assessed current state, strategize and proposed future state along with contemporary trend analysis",
                    "Lead, build or enhancement of existing framework to sustainable quality automation frameworks for customers",
                    "Successfully delivered smaller to large sized project team engagements on time with optimizing quality"
                ]
            },
            {
                "organizationName": "Fidelity",
                "from": {
                    "month": "Apr",
                    "year": "2020"
                },
                "to": {
                    "month": "Apr",
                    "year": "2023"
                },
                "designation": "Senior Project Manager",
                "description": [
                    "The engagement with Fidelity was to build effective automation, evangelize automation across teams as TCOE, establish standard practices, increase test automation coverage, setup continuous testing and smart data setup"
                ],
                "achievements": [
                    "Evaluate modern low code tools and standardize best practices for various testing technologies",
                    "Lead and delivered continuous testing setup for multiple projects",
                    "Developed and evangelized standard practices for testing code quality, unit testing, test coverage across all testing phases"
                ]
            },
            {
                "organizationName": "Deel",
                "from": {
                    "month": "Apr",
                    "year": "2023"
                },
                "to": {
                    "month": "Till",
                    "year": "Date"
                },
                "designation": "Principal Solutions Consultant",
                "description": [
                    "Deel partners with Katalon.",
                    "Katalon is a company that develops and maintains Katalon Studio, a Test Automation platform with AI augumentation. Customer success and engagement is my primary role mantra within Katalon"
                ],
                "achievements": [
                    "Deliver niche test automation solutions for their egdge case and improve coverage",
                    "Advisements to customers and align them to latest quality trends, specifically on automation trends as a part of Katalon's consultation services",
                    "Assist Sales and Marketting in their customer engagements demonstrating product capabilites and provide technical assistance on trial POCs",
                    "Engage with customers and drive their test automation success with the product",
                    "Provide product feedback, ideated thoughts to the respective platform product owners",
                ]
            }
        ],

        "skillDetails": {
            "techSkills": [
                {
                    "skillCategory": "Test Automation",
                    "skills": [
                        {
                            "skill": "Selenium",
                            "ratingPercentage": 80
                        },
                        {
                            "skill": "Appium",
                            "ratingPercentage": 70
                        },
                        {
                            "skill": "Jest",
                            "ratingPercentage": 50
                        },
                        {
                            "skill": "Katalon",
                            "ratingPercentage": 80
                        },
                        {
                            "skill": "Playwright",
                            "ratingPercentage": 80
                        }
                    ]
                },
                {
                    "skillCategory": "Backend Technologies",
                    "skills": [
                        {
                            "skill": "Java",
                            "ratingPercentage": 70
                        },
                        {
                            "skill": "Spring / Spring Boot",
                            "ratingPercentage": 60
                        },
                        {
                            "skill": "Kafka Streaming",
                            "ratingPercentage": 50
                        },
                        {
                            "skill": "SQL / No SQL DBs",
                            "ratingPercentage": 50
                        }
                    ]
                },
                {
                    "skillCategory": "Frontend Technologies",
                    "skills": [
                        {
                            "skill": "Java Script",
                            "ratingPercentage": 70
                        },
                        {
                            "skill": "Reactjs",
                            "ratingPercentage": 60
                        },
                        {
                            "skill": "Redux Toolkit",
                            "ratingPercentage": 50
                        },
                        {
                            "skill": "Jest",
                            "ratingPercentage": 50
                        },
                        {
                            "skill": "Angular",
                            "ratingPercentage": 50
                        }
                    ]
                },
                {
                    "skillCategory": "AI/ML",
                    "skills": [
                        {
                            "skill": "Numpy",
                            "ratingPercentage": 60
                        },
                        {
                            "skill": "Pandas",
                            "ratingPercentage": 60
                        }
                    ]
                },
                {
                    "skillCategory": "Tools",
                    "skills": [
                        {
                            "skill": "CICD",
                            "ratingPercentage": 60
                        },
                        {
                            "skill": "Cloud Techs",
                            "ratingPercentage": 50
                        },
                        {
                            "skill": "Containerization",
                            "ratingPercentage": 70
                        },
                        {
                            "skill": "Git",
                            "ratingPercentage": 70
                        }
                    ]
                }

            ],

            "profSkills": [
                {
                    "profSkill": "Strong Technical Foundation",
                    "profSkillDesc": [
                        "Has deep understanding of current and emerging technologies, as well as knowledge of best practices and industry standards."
                    ]
                },
                {
                    "profSkill": "Problem Resolver",
                    "profSkillDesc": [
                        "Develops strong understanding of the architectural and domain landscape to identify and solve technical problems quickly and efficiently, even under pressure."
                    ]
                },
                {
                    "profSkill": "Creativity and Innovation",
                    "profSkillDesc": [
                        "Have provided thought leadership ideas and solutions towards delivery goals, business issues and enhance product."
                    ]
                },
                {
                    "profSkill": "Continuous Learning",
                    "profSkillDesc": [
                        "I am passionate and committed to continuous learning and staying up-to-date on the latest trends and best practices."
                    ]

                },
                {
                    "profSkill": "Effective Communicator",
                    "profSkillDesc": [
                        "Has inherent capability communicate clearly and assertively with a wide range of stakeholders, including clients, technical executives, business partners and managers.",
                        "Strong presentation skills to effectively communicate technology strategies, plans and complex technology to executive leadership."
                    ]
                },
                {
                    "profSkill": "Leadership Skills",
                    "profSkillDesc": [
                        "Have been a strategical planner, empathetic, diligent, mentor, inclusive, visionary and driver."
                    ]
                }
            ],
            "education": [
                {
                    "qualification": "Bachelor of Engineering (Instrumentation & Control)",
                    "institution": "Sethu Institute of Technology (Madurai Kamaraj University)",
                    "classorscore": "67.2%",
                    "from": "May 1997",
                    "to": "Apr 2001"
                },
                {
                    "qualification": "Python Certification",
                    "institution": "Microsoft",
                    "classorscore": "97%",
                    "from": "Oct 2018",
                    "to": "-"
                }
            ]
        },
        "wins": [
            {
                "company": "Deel",
                "companyWins": [
                    {
                        "winTitle": "Unicom Event 2024",
                        "winDescription": [
                            "Was a speaker in a testing event run by Unicom where I covered 'AI 360 in Test Automation'",
                            "Also was one of the chair person for Q&A session with the best brand representatives providing consultative ideas on Automation in Quality Engineering and Best Practices",
                            "Was awarded best speaker certificate from the event"
                        ]
                    },
                    {
                        "winTitle": "Professional Advisory Consultation on TCOE",
                        "winDescription": [
                            "Have engaged with Security Bank and XL Axiata to setup or enhance current TCOE (practices)",
                            "Consultation provided for best practices, processes, delivery models, competency framework and automation technologies",
                            "Also specific for Katalon customers have provided consultation on Gen AI, ALM, Central Reporting and Enterprise integration"
                        ]
                    },
                    {
                        "winTitle": "Niche Solutions Delivered",
                        "winDescription": [
                            "Developed smart test automation solution for large datasets validation and reconciliation for a large migration project in TCS",
                            "Validating voice services with GCP and AWS",
                            "Inhouse api mocking for large scale middleware projects",
                            "Automation of streaming services"
                        ]
                    }
                ]
            },
            {
                "company": "Fidelity",
                "companyWins": [
                    {
                        "winTitle": "TCOE Automation Pillar",
                        "winDescription": [
                            "Delivered Tech preferred tool mapping by evaluating 14 Automation tools with multiple criteria for various tech vision in Fidelity",
                            "Helped project teams adopt to Power Platform test automation in a short duration of time frame",
                            "Innovative Revised process strategy developed and implemented for development testing coordinated engagement for large and quick to market programmes"
                        ]
                    },
                    {
                        "winTitle": "Unicom Global Event 2021",
                        "winDescription": [
                            "Collaborated in Unicom Global event representing Fidelity"
                        ]
                    }
                ]
            },
            {
                "company": "Infosy Ltd",
                "companyWins": [
                    {
                        "winTitle": "Client - Westpac Bank - Large scale continuous testing strategy",
                        "winDescription": [
                            "Assessment on continuous testing processes and tools across Westpac's BUs",
                            "Discovery on test volumes and infrastructure available",
                            "Produced proposal and implementation plan for dynamically scalling on demand in premise cluster with Test Box",
                            "Implemented solution in 5 Months with some projects experimented and onboarded",
                        ]
                    },
                    {
                        "winTitle": "Client - HSBC Bank - 600 Microservices - 8 Months",
                        "winDescription": [
                            "Strategized delivered fast paced mico-services based automation required for a payments reengineering project in HSBC. The project delivered 600 api in a period of 8 Months and QA strategy was well in control for the delivery",
                            "Feasibility check on tools open source Automation libraries for enterprise bus, streaming services, no SQL DBs during due diligence phase"
                        ]
                    },
                ]
            }
        ]
    }

    return (
        <CVData.Provider value={cvObject}>
            {children}
        </CVData.Provider>
    );
}

export { CVData, CVDataContext };