import './MainContent.css';
import { useContext, useState } from 'react';
import { ContentRouter } from '../../routers/ContentRouter';
import { ContentRouting } from '../../contexts/ContentRoutingContext';
import { Career } from './career/Career';
import { Skills } from './skills/Skills';
import { Wins } from './wins/Wins';
import { Who } from './who/Who';
import { PopCard } from '../popcard/PopCard';
import { SettingsBar } from '../settingsbar/SettingsBar';
import { FloatingMenuBar } from '../floatingmenubar/FloatingMenuBar';

function MainContent({popup, changePopup}) {

    const pn = useContext(ContentRouting);

    const contactPopup = popup && (
        <div className='contact' >
            <PopCard closePop={changePopup} />
        </div>
    );

    return (
        <div className='mncontent'>
            <FloatingMenuBar pop={changePopup} />
            <ContentRouter contextPath={`/${pn.profileName}/`}>
                <Who />
            </ContentRouter>
            <ContentRouter contextPath={`/${pn.profileName}/WhoIAm`}>
                <Who />
            </ContentRouter>
            <ContentRouter contextPath={`/${pn.profileName}/Career`}>
                <Career />
            </ContentRouter>
            <ContentRouter contextPath={`/${pn.profileName}/Skills`}>
                <Skills />
            </ContentRouter>
            <SettingsBar />
            <ContentRouter contextPath={`/${pn.profileName}/Wins`}>
                <Wins />
            </ContentRouter>
            {contactPopup}
        </div>
    );
}

export { MainContent }