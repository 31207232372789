import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeContext } from './contexts/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

root.render(
  <React.StrictMode>
    <ThemeContext>
      <App />
    </ThemeContext>
  </React.StrictMode>
);