import { useState } from "react";
import "./Education.css"

function Education({ card }) {

    return (
        <table className="edutable">
            <thead>
                <tr>
                    <th>Qualification</th>
                    <th>Institute</th>
                    <th>Class/Score</th>
                    <th>From</th>
                    <th>To</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{card.qualification}</td>
                    <td>{card.institution}</td>
                    <td className="score">{card.classorscore}</td>
                    <td className="from">{card.from}</td>
                    <td className="to">{card.to}</td>
                </tr>
            </tbody>
        </table>
    );
}

export { Education }