function Companies({ wins, selected, companyChange }) {


    function clickCompany(event) {
        companyChange(parseInt(event.target.getAttribute("index")))
    }

    const companies = wins.map((win, i) => {
        return (
            <div key={i} index={i} onClick={clickCompany} className="company">
                <div>{win.company}</div>
                {(selected === i) && <div className="ico iarrowr"></div>}
            </div>
        );
    });

    return (
        <div className='winmap'>
            {companies}
        </div>
    );
}

export { Companies }