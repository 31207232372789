import { useState } from "react";
import "./EducationCards.css"
import { Education } from "./education/Education";

function EducationCards({ card, prevCard, nextCard }) {

    return (
        <div className="cardrack">
            <button className="left" onClick={prevCard}>
                <span className="ico icaretleft"></span>
            </button>
            <div className="cardtrack">
                <Education card={card} />
            </div>
            <button className="right" onClick={nextCard}>
                <span className="ico icaretright"></span>
            </button>
        </div>
    );
}

export { EducationCards }