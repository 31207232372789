import { useContext, useReducer } from 'react';
import { ThemeChanging } from '../../contexts/ThemeContext';
import './SettingsBar.css';

const SETTINGS_VISIBILITY_STATE = "Settings Visibility"

function reducer(state, action) {
    switch (action.type) {
        case SETTINGS_VISIBILITY_STATE:
            return {
                ...state,
                settingsVisible: !state.settingsVisible
            };
        default:
            return state;
    }
    
}

function SettingsBar() {

    const [state, dispatch] = useReducer(reducer, {
        settingsVisible: false
    });
    const themeContext = useContext(ThemeChanging);

    const themeIcon = (themeContext.theme === "light") ? (<div className='ico imoon'></div>) : (<div className='ico isun'></div>);

    const settingToggle = state.settingsVisible && (
        <div className='setmenu'>
        <div className='theme' onClick={toggleTheme}>
            {themeIcon}
        </div>
        <div className='clor red' onClick={setTheme}></div>
        <div className='clor orange' onClick={setTheme}></div>
        <div className='clor yellow' onClick={setTheme}></div>
        <div className='clor green' onClick={setTheme}></div>
        <div className='clor blue' onClick={setTheme}></div>
        <div className='clor pink' onClick={setTheme}></div>
    </div>
    );

    function toggleSettings() {
        dispatch({type: SETTINGS_VISIBILITY_STATE});
    }

    function toggleTheme() {
        if (themeContext.theme === "light") themeContext.setTheme("dark");
        else if (themeContext.theme === "dark") themeContext.setTheme("light");
    }

    function setTheme(event) {
        if (event.currentTarget.classList.contains("red") && themeContext.themeColor !== "red") themeContext.setThemeColor("red");
        else if (event.currentTarget.classList.contains("orange") && themeContext.themeColor !== "orange") themeContext.setThemeColor("orange");
        else if (event.currentTarget.classList.contains("yellow") && themeContext.themeColor !== "yellow") themeContext.setThemeColor("yellow");
        else if (event.currentTarget.classList.contains("green") && themeContext.themeColor !== "green") themeContext.setThemeColor("green");
        else if (event.currentTarget.classList.contains("blue") && themeContext.themeColor !== "blue") themeContext.setThemeColor("blue");
        else if (event.currentTarget.classList.contains("pink") && themeContext.themeColor !== "pink") themeContext.setThemeColor("pink");
    }

    

    return (
        <div className='setall'>
            <div className='settings' onClick={toggleSettings}>
                <div className='ico iset'></div>
            </div>
            {settingToggle}
        </div>
    );
}

export { SettingsBar }