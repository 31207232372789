import "./Skillmeter.css";

function Skillmeter({ score }) {
    return (
        <div className='meter'>
            <div className='track'>
                <div className='progress' style={{width:score+'%'}}></div>
            </div>
        </div>
    );
}

export { Skillmeter }