import { Skillmeter } from "../skillmeter/Skillmeter";

function Skill({skill}) {
    return (
        <div className='skill'>
            <div className='name'>
                {skill.skill}
            </div>
            <Skillmeter score={skill.ratingPercentage} />
        </div>
    );
}

export { Skill }; 