import './FloatingMenuItems.css';
import { useState, useEffect, useContext } from 'react';
import { ContentRouting } from '../../../contexts/ContentRoutingContext';

function FloatingMenuItems({ menuItems, closeMenu }) {

    const [mnuItems, setMnuItems] = useState([]);
    const cr = useContext(ContentRouting);

    function setItems(title, icon, actv) {
        return (
            <li key={title} onClick={selectMenuItem}>
                <a href='#' className={actv}>
                    <span>|</span>
                    <span className={icon}></span>
                    <span>{title}</span>
                </a>
            </li>
        );
    }

    function selectMenuItem(event) {
        closeMenu(false)
        setMnuItems(
            menuItems.map((item) => {
                const icon = "ico " + item.icon;
                let actv = "inactive";
                if (event.currentTarget.innerText.endsWith(item.title)) {
                    actv = "active";
                    cr.setNavPath(event, item.title);
                }
                return setItems(item.title, icon, actv);
            }))
    }

    useEffect(
        () => {
            setMnuItems(
                menuItems.map((item, index) => {
                    const icon = "ico " + item.icon;
                    const actv = (index === 0) ? "active" : "inactive"
                    return setItems(item.title, icon, actv);
                })
            );
            cr.setWindowPath(menuItems[0].title);
        }, []
    );

    return (
        <ul className="nav">
            {mnuItems}
        </ul>
    );
}

export { FloatingMenuItems }