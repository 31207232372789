import "./ProfCards.css"
import { useEffect } from "react";

function ProfCards({ cards }) {

    const allcards = cards.map((card) => {
        const cardDesc = card.profSkillDesc.map((desc, i) => {
            return (
                <p className="bullets" key={i}>
                    {desc}
                </p>
            );
        });

        return (
            <div className="profcard" key={card.profSkill}>
                <h4 className="title">{card.profSkill}</h4>
                {cardDesc}
            </div>
        );
    });

 
    function leftScroll() {
        const scrollCards = document.querySelector(".cardrack>.cardtrack>.cardtrackin");
        scrollCards.scrollBy({
            left: -50,
            behavior: "smooth"
        });
    }

    function rightScroll() {
        const scrollCards = document.querySelector(".cardrack>.cardtrack>.cardtrackin");
        scrollCards.scrollBy({
            left: 50,
            behavior: "smooth"
        });
    }

    useEffect(
        () => {
            document.addEventListener("DOMContentLoaded", handleScroll);
            const scrollCards = document.querySelector(".cardrack>.cardtrack>.cardtrackin");
            const leftButton = document.querySelector(".cardrack>.left");
            const rightButton = document.querySelector(".cardrack>.right");
            const scrollLength = scrollCards.scrollWidth - scrollCards.clientWidth;

            function handleScroll() {

                function checkScroll() {
                    const currentScroll = scrollCards.scrollLeft;
                    if (currentScroll === 0) {
                        leftButton.setAttribute("disabled", "true");
                        rightButton.removeAttribute("disabled");
                    } else if (currentScroll === scrollLength) {
                        rightButton.setAttribute("disabled", "true");
                        leftButton.removeAttribute("disabled");
                    } else {
                        leftButton.removeAttribute("disabled");
                        rightButton.removeAttribute("disabled");
                    }
                }

                scrollCards.addEventListener("scroll", checkScroll);
                window.addEventListener("resize", checkScroll);
                checkScroll();



                leftButton.addEventListener("click", leftScroll);
                rightButton.addEventListener("click", rightScroll);
            }

            return () => {
                document.removeEventListener("DOMContentLoaded", handleScroll);
            }
        },
        []
    );



    return (
        <div className="cardrack">
            <button className="left" onClick={leftScroll}>
                <span className="ico icaretleft"></span>
            </button>
            <div className="cardtrack">
                <div className="cardtrackin">
                    {allcards}
                </div>
            </div>
            <button className="right" onClick={rightScroll}>
                <span className="ico icaretright"></span>
            </button>
        </div>
    );
}

export { ProfCards }