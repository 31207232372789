import './FloatingMenuBar.css'
import { useState } from 'react';
import { ButtonArrow } from '../button/ButtonArrow';
import { FloatingMenuItems } from './FloatingMenuItems/FloatingMenuItems';

function FloatingMenuBar({pop}) {

    const [menuClose, setMenuClose] = useState(false)

    function changeIcon() {
        setMenuClose(!menuClose)
    }

    const menuItems = [
        { title: "Who I Am", icon: "iuser" },
        { title: "Career", icon: "iprofile" },
        { title: "Wins", icon: "itrophy" },
        { title: "Skills", icon: "istar" }
    ];

    return (
        <div>
            {
                menuClose &&
                <div className='menu'>

                        <div className="heading">
                            <a href="#">
                                <span>Pravin's</span>
                            </a>
                        </div>
                        <FloatingMenuItems menuItems={menuItems} closeMenu={setMenuClose} />
                        <div className='footer'>
                            <ButtonArrow func={pop} text="Interested" />
                        </div>

                </div>
            }
            <div className="floatingmenubar" onClick={changeIcon}>
                {!menuClose && <div className="ico iham"></div>}
                {menuClose && <div className="ico ixt"></div>}
            </div>
        </div>
    );
}

export { FloatingMenuBar }