import './Skills.css';
import { SkillCards } from './skillcards/SkillCards';
import { ProfCards } from './profcards/ProfCards';
import { EducationCards } from './educationcards/EducationCards';
import { CVData } from "../../../contexts/CVDataContext";
import { useReducer, useContext } from 'react';
import { Indicators } from './indicators/Indicators';

const SCROLL_SKILL_CARD = "Scroll Skill Card";
const SCROLL_EDU_CARD = "Scroll Edu Card";
const CHANGE_SKILL = "Change Skill";

function reducer(state, action) {
    switch (action.type) {
        case SCROLL_SKILL_CARD:
            return (
                { ...state, skillCard: action.payload }
            );
        case SCROLL_EDU_CARD:
            return (
                { ...state, eduCard: action.payload }
            );
        case CHANGE_SKILL:
            return (
                { ...state, skillType: action.payload }
            );
        default:
            return state;
    }
}

function Skills() {

    const skills = useContext(CVData);
    const techcards = skills.skillDetails.techSkills;
    const skillLength = techcards.length;
    const [state, dispatch] = useReducer(reducer, {
        skillCard: 0,
        eduCard: 0,
        skillType: "Technology"
    })
    const profCards = skills.skillDetails.profSkills;
    const eduCards = skills.skillDetails.education;

    function getNextSkillCard() {
        let card = 0;
        if (state.skillCard === skillLength - 1) card = 0;
        else card = state.skillCard + 1;
        dispatch({
            type: SCROLL_SKILL_CARD,
            payload: card
        });
    }

    function getPrevSkillCard() {
        let card = 0;
        if (state.skillCard === 0) card = skillLength - 1;
        else card = state.skillCard - 1;
        dispatch({
            type: SCROLL_SKILL_CARD,
            payload: card
        });
    }

    function getNextEduCard() {
        if (eduCards.length - 1 === state.eduCard) {
            dispatch({
                type: SCROLL_EDU_CARD,
                payload: 0
            });
            indicatorChange(0, SCROLL_EDU_CARD);
        }
        else {
            dispatch({
                type: SCROLL_EDU_CARD,
                payload: state.eduCard + 1
            });
            indicatorChange(state.eduCard + 1, SCROLL_EDU_CARD);
        }
    }

    function getPrevEduCard() {
        if (state.eduCard === 0) {
            dispatch({
                type: SCROLL_EDU_CARD,
                payload: eduCards.length - 1
            });
            indicatorChange(eduCards.length - 1, SCROLL_EDU_CARD);
        }
        else {
            dispatch({
                type: SCROLL_EDU_CARD,
                payload: state.eduCard - 1
            });
            indicatorChange(state.eduCard - 1, SCROLL_EDU_CARD);
        };
    }

    function indicatorChange(card, type) {
        switch (type) {
            case SCROLL_SKILL_CARD:
                dispatch({
                    type: SCROLL_SKILL_CARD,
                    payload: card
                });
                break;
            case SCROLL_EDU_CARD:
                dispatch({
                    type: SCROLL_EDU_CARD,
                    payload: card
                });
                break;
        }
    }

    function handleSkillView(event) {
        const element = document.querySelector(".skillmap .sselect");
        if (element !== null) element.classList.remove("sselect");
        event.target.classList.add("sselect");
        switch (event.target.innerHTML) {
            case "Professional":
                dispatch({
                    type: CHANGE_SKILL,
                    payload: "Professional"
                });
                break;
            case "Technology":
                dispatch({
                    type: CHANGE_SKILL,
                    payload: "Technology"
                });
                break;
            case "Education":
                dispatch({
                    type: CHANGE_SKILL,
                    payload: "Education"
                });
                break;
            default:
                dispatch({
                    type: CHANGE_SKILL,
                    payload: "Professional"
                });

        }
    }

    return (
        <section className='skills section'>
            <div className='container'>
                <div className='skrow'>
                    <div className='heading'>
                        <h3>
                            Skills
                        </h3>
                    </div>
                    <div className='skillcontent'>
                        {
                            (
                                state.skillType === "Technology" &&
                                <div className="techskills">
                                    <div className='scrolll'>
                                        <span className='ico icaretleft' onClick={getPrevSkillCard}></span>
                                    </div>
                                    <SkillCards card={techcards[state.skillCard]} />
                                    <div className='scrollr'>
                                        <span className='ico icaretright' onClick={getNextSkillCard}></span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            (
                                state.skillType === "Professional" &&
                                <ProfCards cards={profCards} />
                            )
                        }
                        {
                            (
                                state.skillType === "Education" &&
                                <EducationCards card={eduCards[state.eduCard]} prevCard={getPrevEduCard} nextCard={getNextEduCard} />
                            )
                        }
                    </div>
                    {
                        ((state.skillType === "Technology") ?
                            <Indicators repeat={skillLength} selected={state.skillCard} change={indicatorChange} type={SCROLL_SKILL_CARD} /> :
                            (state.skillType === "Education") ?
                                <Indicators repeat={eduCards.length} selected={state.eduCard} change={indicatorChange} type={SCROLL_EDU_CARD} /> :
                                <Indicators repeat={1} selected={0} />
                        )
                    }
                    <div className='skillmap'>
                        <span onClick={handleSkillView} className='sselect'>Technology</span>
                        <span onClick={handleSkillView}>Professional</span>
                        <span onClick={handleSkillView}>Education</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export { Skills }