import { Skill } from "./skill/Skill";

function SkillCards({ card }) {

    const skills = card.skills.map((skill) => {
        return <Skill skill={skill} key={card.skillCategory+" "+skill.skill} />;
    });

    return (
        <div className='description'>
            <div className='skillcard'>
                <div className='title'>
                    {card.skillCategory}
                </div>
                {skills}
            </div>
        </div>
    );
}

export { SkillCards };