import './App.css';
import { ContentRoutingContext } from './contexts/ContentRoutingContext';
import { MainContent } from './components/maincontent/MainContent';
import { SideBar } from './components/sidebar/SideBar';
import { ThemeChanging } from './contexts/ThemeContext';
import { useContext, useState } from 'react';
import { CVDataContext } from './contexts/CVDataContext';

function App() {

  const themeContext = useContext(ThemeChanging);
  const classList = `App ${themeContext.theme} ${themeContext.themeColor}`;

  const [openPop, setOpenPop] = useState(false);

  return (
    <ContentRoutingContext>
      <CVDataContext>
        <div className={classList}>
          <SideBar pop={setOpenPop} />
          <MainContent popup={openPop} changePopup={setOpenPop} />
        </div>
      </CVDataContext>
    </ContentRoutingContext>
  );
}

export default App;