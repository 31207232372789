import './SideBar.css';
import { MenuItems } from './MenuItems/MenuItems';
import { ButtonArrow } from '../button/ButtonArrow';
import { useContext } from 'react';
import { CVData } from '../../contexts/CVDataContext';

function SideBar({ pop }) {

    const data = useContext(CVData)
    const href = data.personalDetails.website

    const menuItems = [
        { title: "Who I Am", icon: "iuser" },
        { title: "Career", icon: "iprofile" },
        { title: "Wins", icon: "itrophy" },
        { title: "Skills", icon: "istar" }
    ];

    return (
        <div className="sidebar">
            <div className="heading">
                <a href="#">
                    <span>Pravin's</span>
                </a>
            </div>
            <MenuItems menuItems={menuItems} />
            <div className='footer'>
                <ButtonArrow href="" func={pop} text="Interested" />
            </div>
        </div>
    );
}

export { SideBar }