function Timeline({ cards, selectedCard, action }) {

    const cardClicked = (event) => {
        const clickedCard = event.target
        const cardIndex = clickedCard.closest('.card').getAttribute("index");
        action(parseInt(cardIndex));
    };

    const allCards = cards.map((card, i) => {

        const details = (
            <div>
                <div className='period'>
                    <span className='from'>
                        <span className='month'>{card.from.month}</span>
                        <span>'</span>
                        <span className='year'>{card.from.year}</span>
                    </span>
                    <span>-</span>
                    <span className='to'>
                        <span className='month'>{card.to.month}</span>
                        <span>'</span>
                        <span className='year'>{card.to.year}</span>
                    </span>
                </div>
                <div className='company'>
                    {card.organizationName}
                </div>
            </div>
        );

        return (
            <div className='card' index={i} key={i} onClick={cardClicked} scard={(selectedCard === i)?"true":"false"}>
                {details}
            </div>
        );
    });



    return (
        <div className='timeline'>
            <div className='cards'>
                {allCards}
            </div>
        </div>
    );
}

export { Timeline }