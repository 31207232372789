import "./Indicators.css";

function Indicators({ repeat, selected, change, type }) {

    function changeCategory(event) {
        const index = event.target.getAttribute("index");
        change(parseInt(index), type);
    }

    const indicators = [];
    for (let i = 0; i < repeat; i++) {
        indicators.push(
            (selected === i) ? <span className='ico icirs' index={i} key={i} /> : <span className='ico icir' index={i} key={i} onClick={changeCategory} />
        )
    };


    return (
        <div className='indicator'>
            {indicators}
        </div>
    );
}

export { Indicators };